.products-page{
    background-image: url("../images/oak_pattern.png");
    background-size: 100px;
}
.products-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 15px;
    gap: 15px;
}
.product-card{
    padding: 10px;
    text-align: center;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.367);
    border-radius: 5px;
    background-color: white;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.422);
}
.product-card__image-container{
    position: relative;
    padding-top: 120%;
}
.product-card__image{
    padding: 15px;
    position: absolute;
    top: 0;
    left: 50%;
    translate: -50%;
    height: 100%;
}
.product-card__name{
    margin-top: 10px;
}
.product-card__price{
    margin-top: 30px;
}
.product-card__buy{
    padding: 10px;
    margin: 10px;
    width: 80%;
    border-radius: 5px;
    background-color: #028a02;
    color: white;
    font-weight: bold;
}
.product-card__buy:active{
    background-color: #028a02e3;
}

@media only screen and (max-width: 320px) {
    .products-container{
        grid-template-columns: repeat(1, 1fr);
    }
}


@media only screen and (min-width: 768px) {
    .products-container{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (min-width: 992px) {
    .products-container{
        grid-template-columns: repeat(4, 1fr);
    }
}