.navbar{
    background-color: black;
}
.navbar__list{
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    padding: 15px;
}
.navbar__link{
    position: relative;
    text-align: center;
    text-decoration: none;
    color: white;
    font-weight: bold;
    padding: 15px;
}
.navbar__link:active{
    transition: 100ms;
    background-color: #ffffff37;
}
.navbar__number-container{
    position: absolute;
    top: 50%;
    left: 85%;
    display: inline-block;
    min-width: 18px;
    background-color: green;
    border-radius: 100px;
    padding: 5px;
    translate: 0% -50%;
}
.navbar__cart-number{
    display: block;
    min-width: 18px;
}