.home-page{
    position: relative;
    height: calc(100vh - 49px);
    color: white;
    text-shadow: 2px 3px 3px rgba(0, 0, 0, 0.486);
    background-color: aquamarine;
    background-image: url("../images/minecraft-blocks-wallpapers.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
}
.home-page__text-container{
    background-color: rgba(0, 0, 0, 0.5);
    width: 90%;
    max-width: 600px;
    padding: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
}
.home-page__text-container > h1{
    padding: 10px;
    text-align: justify;
}
.home-page__text-container > p{
    padding: 10px;
    line-height: 1.5em;
    text-indent: 30px;
    text-align: justify;
    font-size: 1.2em;
}

